import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { tradingRulesSelector } from '../../../../redux/tradingRules';
import { Datafeed } from './datafeed';

function TvEquity() {
  const widgetRef = useRef(null);
  const tvRef = useRef();
  const { equity_symbols } = useSelector(tradingRulesSelector);

  useEffect(() => {
    const widgetOptions = {
      // preset: "mobile",
      // BEWARE: no trailing slash is expected in feed URL
      // datafeed: new window.Datafeeds.UDFCompatibleDatafeed("https://demo_feed.tradingview.com"),
      datafeed: Datafeed,
      symbol: equity_symbols[0],
      interval: 'D',
      // time_frames: [
      //   { text: "1Y", resolution: "1W" },
      // ],
      container: tvRef.current,
      fullscreen: true,
      autosize: false,
      library_path: '/js/charting_library/',
      overrides: {
        'paneProperties.background': '#222222',
        'paneProperties.vertGridProperties.color': '#454545',
        'paneProperties.horzGridProperties.color': '#454545',
        'scalesProperties.textColor': '#AAA',
        // "mainSeriesProperties.style": 2,
      },
    };

    widgetRef.current = new window.TradingView.widget(widgetOptions);
    // widgetRef.current.activeChart().setChartType(2)

    return () => {
      if (widgetRef.current !== null) {
        widgetRef.current.remove();
        widgetRef.current = null;
      }
    };
  }, [equity_symbols]);

  return (
    <div className="tv-chart">
      <span className="chart-name">Equity curve</span>
      <div ref={tvRef} className={'TVChartContainer'} />
    </div>
  );
}

export default TvEquity;
