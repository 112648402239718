import { DesktopDatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';
import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formatDate, sub1year, add1year } from '../../../utils/dates';
import { setDates, tableDataSelector } from '../../../redux/table';
import { setIsTrDataActual } from '../../../redux/tradingRules';

import styles from './index.module.scss';

function DateRangePicker() {
  const dateFromRef = useRef();
  const dateToRef = useRef();
  const dispatch = useDispatch();
  const { dates } = useSelector(tableDataSelector);

  useEffect(() => {
    if (dateFromRef && dateToRef) {
      dateFromRef.current.querySelector('input').disabled = true;
      dateToRef.current.querySelector('input').disabled = true;
    }
  }, []);

  const handleChangeDate = (prop) => (dateObj) => {
    let dateUpd = { ...dates, [prop]: formatDate(dateObj) };
    if (prop === 'from') {
      dateUpd.minTo = add1year(dateObj);
      if (dayjs(dateUpd.to).unix() < dayjs(dateUpd.minTo).unix()) {
        dateUpd.to = add1year(dateObj);
      }
    } else {
      dateUpd.maxFrom = sub1year(dateObj);
      if (dayjs(dateUpd.from).unix() > dayjs(dateUpd.maxFrom).unix()) {
        dateUpd.from = sub1year(dateObj);
      }
    }
    dispatch(setDates(dateUpd));
    dispatch(setIsTrDataActual(false));
  };

  return (
    <div className={styles.dates_container}>
      <DesktopDatePicker
        label="Start date"
        ref={dateFromRef}
        mask="____-__-__"
        inputFormat="YYYY-MM-DD"
        value={dates.from}
        maxDate={dayjs(dates.maxFrom)}
        onChange={handleChangeDate('from')}
        renderInput={(params) => <TextField sx={{ width: 150 }} {...params} />}
      />
      <img className={styles.arrow} src="/images/arrow_right_gray.svg" alt="arrow" />
      <DesktopDatePicker
        label="End date"
        ref={dateToRef}
        mask="____-__-__"
        inputFormat="YYYY-MM-DD"
        value={dates.to}
        minDate={dayjs(dates.minTo)}
        maxDate={dayjs(dates.maxTo)}
        onChange={handleChangeDate('to')}
        renderInput={(params) => <TextField sx={{ width: 150 }} {...params} />}
      />
    </div>
  );
}

export default DateRangePicker;
