import { useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Radio from '@mui/material/Radio';

import styles from './table.module.scss';

export const renderRadioButton = (selectedRowId) => (value, tableMeta) =>
  (
    <Radio
      size="small"
      checked={selectedRowId === tableMeta.rowIndex}
      sx={{
        padding: 0,
        color: '#52956A',
        fontSize: 20,
        '&.Mui-checked': {
          color: '#52956A',
        },
      }}
    />
  );

export const customBodyRender =
  (toFixed = 3) =>
  (value) =>
    value.toFixed(toFixed);
export const rowNumberRender = (value, tableMeta) => {
  let rowIndex = Number(tableMeta.rowIndex) + 1;
  return <span>{rowIndex}</span>;
};

export const renderSaveToList = (onOpenModal) => (value, tableMeta) => {
  return tableMeta.columnData.name === 'Add' ? (
    <span
      onClick={(e) => {
        e.stopPropagation();
        onOpenModal(tableMeta.rowIndex);
      }}
      style={{ fontSize: '16px', padding: '0 10px', cursor: 'pointer' }}
    >
      +
    </span>
  ) : (
    value
  );
};

export const customHeadRender =
  (reducers, isNum, filterOn, info) =>
  ({ name, label }) =>
    (
      <CustomHeadRender
        name={name}
        key={name + label}
        reducers={reducers}
        isNum={isNum}
        label={label}
        filterOn={filterOn}
        info={info}
      />
    );

const CustomHeadRender = ({ name, reducers, isNum, label, filterOn, info }) => {
  let { applyFilter, clearFilter, setFilter, setRangeFilter, setSort, dataSelector } = reducers;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  let open = Boolean(anchorEl);

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleFilter = () => {
    dispatch(applyFilter());
    setAnchorEl(null);
  };

  const handleSort = (sortType) => () => {
    dispatch(setSort({ name, sortType }));
    dispatch(applyFilter());
    setAnchorEl(null);
  };

  const handleClearFilter = () => {
    dispatch(clearFilter(name));
    dispatch(applyFilter());
    setAnchorEl(null);
  };

  // renderers
  const { filter, rangeFilter, sort } = useSelector(dataSelector);
  let filterName = filter?.[name];
  let rangeName = rangeFilter?.[name];
  let sortName = sort?.[name];
  // let isActiveFilter = Boolean(sortName || rangeName || filterName);
  // console.log(isActiveFilter)

  const handleValue = (filterType) => (e) => {
    let value = e.target.value;
    // console.log(filterType, value)
    if (filterType === 'from' || filterType === 'to') {
      dispatch(setRangeFilter({ name, filterType, value }));
    } else {
      dispatch(setFilter({ name, filterType, value }));
    }
  };

  const textFieldRenderer = (filterType) =>
    filterType === 'from' || filterType === 'to' ? (
      <TextField
        value={rangeName?.[filterType] || ''}
        onClick={(e) => e.stopPropagation()}
        onChange={handleValue(filterType)}
        sx={{ ml: 1, width: 80 }}
        inputProps={{ step: 0.01 }}
        type="number"
        variant="standard"
      />
    ) : (
      <TextField
        value={filterName?.[filterType] || ''}
        onClick={(e) => e.stopPropagation()}
        onChange={handleValue(filterType)}
        sx={{ ml: 1, width: 80 }}
        inputProps={{ step: 0.01 }}
        type="number"
        variant="standard"
      />
    );

  const menuItemsRenderer = (isNum) => {
    if (!isNum) {
      return (
        <div>
          <MenuItem onClick={handleClearFilter}>
            <DeleteOutlinedIcon sx={{ mr: 0.5 }} />
            Clear filter
          </MenuItem>
          <MenuItem onClick={handleSort('az')} sx={setActiveItemStyles(sortName === 'az')}>
            Sort А to Z
          </MenuItem>
          <MenuItem onClick={handleSort('za')} sx={setActiveItemStyles(sortName === 'za')}>
            Sort Z to A
          </MenuItem>
        </div>
      );
    } else {
      return (
        <div>
          <MenuItem onClick={handleClearFilter}>
            <DeleteOutlinedIcon sx={{ mr: 0.5 }} />
            Clear filter
          </MenuItem>
          <MenuItem onClick={handleSort('asc')} sx={setActiveItemStyles(sortName === 'asc')}>
            Sort ascending
          </MenuItem>
          <MenuItem onClick={handleSort('desc')} sx={setActiveItemStyles(sortName === 'desc')}>
            Sort descending
          </MenuItem>
          <MenuItem onClick={handleFilter} sx={setActiveItemStyles(filterName?.['gt'])}>
            "Х" greater than:
            {textFieldRenderer('gt')}
          </MenuItem>
          <MenuItem onClick={handleFilter} sx={setActiveItemStyles(filterName?.['lt'])}>
            "Х" less than:
            {textFieldRenderer('lt')}
          </MenuItem>
          <MenuItem onClick={handleFilter} sx={setActiveItemStyles(filterName?.['et'])}>
            "Х" equal to:
            {textFieldRenderer('et')}
          </MenuItem>
          <MenuItem onClick={handleFilter} sx={setActiveItemStyles(rangeName)}>
            "Х" is in the range:
            {textFieldRenderer('from')} -{textFieldRenderer('to')}
          </MenuItem>
        </div>
      );
    }
  };

  return (
    <th className="custom-head">
      <div className="wrapper">
        <span className="title">
          {label || name}{' '}
          {info && (
            <span className={styles.info} title={info}>
              ?
            </span>
          )}
        </span>
        {filterOn && (
          <>
            <IconButton onClick={handleOpenMenu} className={styles.filterIcon}>
              <FilterListIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
              {menuItemsRenderer(isNum)}
            </Menu>
          </>
        )}
      </div>
    </th>
  );
};

const setActiveItemStyles = (val) => ({ backgroundColor: val && '#EDF5F0' });

export const setCellProps = () => ({
  style: {
    textAlign: 'center',
  },
});
