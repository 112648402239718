import { useSelector } from 'react-redux'
import { analysisDataSelector } from '../../../redux/analysis'
import styles from './statistics.module.scss'

function Statistics() {
  const { statistics } = useSelector(analysisDataSelector)

  return (
    <div className={styles.statistics}>
      <h3>Spread statistics</h3>
      <div className="params">
        {
          Object.entries(statistics).map(([key, value]) =>
            <div className="param" key={key}>
              <span className="title">{key}:</span>
              <span className="value">{value.toFixed(3)}</span>
            </div>
          )
        }
        {/* <div className="param">
          <span className="title">Mean Reversion Coefficient (MRC):</span>
          <span className="value">-0.014794</span>
        </div>
        <div className="param">
          <span className="title">Skewness:</span>
          <span className="value">{statistics['Skewness']}</span>
        </div>
        <div className="param">
          <span className="title">Doornik-Hansen normality test (p-value):</span>
          <span className="value">{'(<< 0.0001)'}</span>
        </div>
        <div className="param">
          <span className="title">Half-life:</span>
          <span className="value">46.85</span>
        </div>
        <div className="param">
          <span className="title">Kurtosis:</span>
          <span className="value">{statistics['Kurtosis']}</span>
        </div> */}
      </div>
    </div>
  )
}

export default Statistics