import axios from 'axios';
import { baseUrl } from '../config';
import { getConfig } from '../utils/axios';

export const getRulesData = async (params) => {
  try {
    const res = await axios.post(baseUrl + '/pair/optimize', params, getConfig())
    console.log(res.data)
    return res.data
  } catch (err) {
    alert(err.message)
    return null
  }
}