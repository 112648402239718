import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { getTableData } from '../api/table';
import { formatDate, sub1year } from '../utils/dates';
import { filterReducers, initFilterState } from '../components/reusable/CustomTable/reducers';
// import { pairsData } from "../api/samples/pairs-resp";

export const fetchTableData = createAsyncThunk('fetchTableData', async (_, thunkAPI) => {
  let {
    tableData: { method, dates, tickers },
  } = thunkAPI.getState();
  let params = {
    // tickers: "AAPL,GS,C,JPM,NVDA,ARVL,JNJ,BIDU,TSLA,AAL,PFE,TSN",
    // hedge_ratio_method: "min_half_life",
    // start: "2020-04-07",
    tickers,
    hedge_ratio_method: method,
    start: dates.from,
    end: dates.to,
  };
  console.log(params);
  const data = await getTableData(params);
  // console.log(data)
  return data;
});

export const tableSlice = createSlice({
  name: 'tableData',
  initialState: {
    fileName: '',
    tickers: '',
    tickersPair: '',
    // tickersPair: 'AAPL,PFE',
    selectedRowId: null,
    loading: false,
    initData: null,
    data: null,
    // initData: pairsData,
    // data: pairsData,
    ...initFilterState,
    report: null,
    dates: {
      from: sub1year(),
      maxFrom: sub1year(),
      to: formatDate(),
      minTo: null,
      maxTo: formatDate(),
    },
    method: 'OLS',
  },
  reducers: {
    setTickers: (state, { payload }) => {
      state.tickers = payload.tickers;
      state.fileName = payload.name;
    },
    setDates: (state, action) => {
      state.dates = action.payload;
    },
    setMethod: (state, action) => {
      state.method = action.payload;
    },
    setSelectedRowId: (state, action) => {
      let data = current(state).data;
      let selectedRowId = action.payload;
      state.selectedRowId = selectedRowId;
      state.tickersPair = data[selectedRowId]['First Leg'] + ',' + data[selectedRowId]['Second Leg'];
    },
    ...filterReducers(true),
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [fetchTableData.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [fetchTableData.fulfilled]: (state, { payload }) => {
      // console.log(payload)
      state.initData = payload?.pairs || [];
      state.data = payload?.pairs || [];
      state.report = payload?.report;
      state.loading = false;
    },
    // [fetchTableData.rejected]: (state, { payload }) => {
    //   state.loading = false
    // },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTickers,
  setDates,
  setMethod,
  setSelectedRowId,
  setFilter,
  setRangeFilter,
  setSort,
  clearFilter,
  clearAllFilters,
  applyFilter,
} = tableSlice.actions;

export const tableDataSelector = (state) => state.tableData;

export default tableSlice.reducer;
