import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { tradingListActions, tradingListSelectors } from '../redux/modules/trading-list';

const useFetchTradingList = () => {
  const dispatch = useDispatch();
  const list = useSelector(tradingListSelectors.selectTradingLists);

  useEffect(() => {
    !list.length && dispatch(tradingListActions.fetchTradingList());
  }, []);
};

export default useFetchTradingList;
