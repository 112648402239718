import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { authSelectors } from '../../redux/modules/auth';

export const RequireAuth = ({ children }) => {
  const location = useLocation();
  const user = useSelector(authSelectors.selectUser);

  return user ? children : <Navigate to="/login" state={{ path: location.pathname }} replace />;
};
