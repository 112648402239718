import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import analysisReducer from './analysis';
import authReducer from './modules/auth';
import tableDataReducer from './table';
import tradingRulesReducer from './tradingRules';
import tradingListReducer from './modules/trading-list';
import mainSaga from './saga';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    auth: authReducer,
    tableData: tableDataReducer,
    analysisData: analysisReducer,
    tradingRules: tradingRulesReducer,
    tradingList: tradingListReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(sagaMiddleware),
});

sagaMiddleware.run(mainSaga);

export default store;
