import styles from './nodata.module.scss';

function NoData() {
  return (
    <div className={styles.nodata}>
      <img className="logo" src="/images/logo.png" alt="logo" />
    </div>
  );
}

export default NoData;
