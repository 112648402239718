import { getType } from 'typesafe-actions';

import { tradingListActions } from '.';

const initialState = {
  data: {
    lists: [],
    rules: [],
  },
  createList: {
    params: null,
  },
  deleteList: {
    id: null,
  },
  deleteRule: {
    id: null,
  },
  error: null,
  isPending: false,
  isError: false,
};

const tradingListReducer = (state = initialState, action) => {
  switch (action.type) {
    case getType(tradingListActions.fetchTradingListAsync.request): {
      return {
        ...state,
        isPending: true,
      };
    }
    case getType(tradingListActions.fetchTradingListAsync.success): {
      return {
        ...state,
        isPending: false,
        data: action.payload,
      };
    }
    case getType(tradingListActions.createOrUpdateListAsync.request): {
      return {
        ...state,
        createList: {
          params: action.payload,
        },
      };
    }
    case getType(tradingListActions.createOrUpdateListAsync.success): {
      return {
        ...state,
        data: {
          ...state.data,
          lists: [...state.data.lists, action.payload],
        },
      };
    }
    case getType(tradingListActions.deleteTradingListAsync.request): {
      return {
        ...state,
        deleteList: {
          id: action.payload,
        },
      };
    }
    case getType(tradingListActions.deleteTradingListAsync.success): {
      return {
        ...state,
        data: {
          ...state.data,
          lists: state.data.lists.filter((l) => l.id !== state.deleteList.id),
        },
      };
    }
    case getType(tradingListActions.saveRuleAsync.success): {
      return {
        ...state,
        data: {
          ...state.data,
          rules: [...state.data.rules, action.payload],
        },
      };
    }
    case getType(tradingListActions.deleteRuleAsync.request): {
      return {
        ...state,
        deleteRule: {
          id: action.payload.id,
        },
      };
    }
    case getType(tradingListActions.deleteRuleAsync.success): {
      return {
        ...state,
        data: {
          ...state.data,
          rules: state.data.rules.filter((r) => r.id !== state.deleteRule.id),
        },
      };
    }
    default:
      return state;
  }
};

export default tradingListReducer;
