import { call, put } from 'typed-redux-saga';

const asyncEntity = (entity, asyncFn, cds) =>
  function* fetchEntity(asyncFnPayload, requestPayload) {
    try {
      const request = entity.request(requestPayload || asyncFnPayload);
      yield put(request);
      if (cds?.request) yield call(cds.request, request);

      const res = yield* call(asyncFn, asyncFnPayload);
      const success = entity.success(res);
      yield put(success);
      if (cds?.success) yield call(cds.success, success);
    } catch (err) {
      const failure = entity.failure(err);
      yield put(failure);
      if (cds?.failure) yield call(cds.failure, failure);
    }
  };

export default asyncEntity;
