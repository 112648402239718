import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAnalysisData } from '../api/analysis';

export const fetchAnalysisData = createAsyncThunk('fetchAnalysisData', async (_, thunkAPI) => {
  let {
    tableData: { method, dates, tickersPair },
  } = thunkAPI.getState();
  let params = {
    // tickers: "AAPL,PFE",
    // start: "2020-04-07",
    // hedge_ratio_method: "min_half_life",
    tickers: tickersPair,
    start: dates.from,
    end: dates.to,
    hedge_ratio_method: method,
  };
  console.log(params);
  const res = await getAnalysisData(params);
  // console.log(res)
  return res;
});

export const analysisSlice = createSlice({
  name: 'analysisData',
  initialState: {
    data: null,
    symbols: [],
    statistics: {},
    plots_config: null,
    loading: false,
  },
  reducers: {},
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [fetchAnalysisData.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [fetchAnalysisData.fulfilled]: (state, { payload }) => {
      state.data = payload?.price_plot_data;
      if (payload?.price_plot_data) state.symbols = Object.keys(payload.price_plot_data).reverse();
      state.statistics = payload?.spread_statistics || {};
      state.plots_config = payload?.plots_config;
      state.loading = false;
    },
    // [fetchAnalysisData.rejected]: (state, { payload }) => {
    //   state.loading = false
    // },
  },
});

// Action creators are generated for each case reducer function
// export const {  } = analysisSlice.actions;

export const analysisDataSelector = (state) => state.analysisData;

export const selectAnalysisSymbols = (state) => state.analysisData.symbols;
export const selectAnalysisStatisticsHedgeRatio = (state) => state.analysisData.statistics['Hedge Ratio'];

export default analysisSlice.reducer;
