import store from '../../../../redux/store'
import { valueOfDate } from '../../../../utils/dates';

const configurationData = {
  supports_marks: false,
  supports_search: true,
  supports_group_request: false,
  supports_timescale_marks: false,
  supported_resolutions: '1S',
};

const mapData = (data) => {
  let bars = data.map(t => {
    return {
      low: t.close,
      time: valueOfDate(t.time),
      high: t.close,
      open: t.close,
      close: t.close,
      // volume: t.volume,
    }
  })
  // console.log(bars);
  return bars
}

const createSymbolInfo = (symbolName) => ({
  symbol: symbolName,
  full_name: symbolName,
  description: symbolName,
  exchange: symbolName,
  type: '',
  has_intraday: true,
  name: symbolName,
  // ticker: symbolName,
  // interval: configurationData.supported_resolutions[0],
  // session: '24x7',
  // has_seconds: true,
  // currency_code: 'USD',
  // format: 'volume',
})

export const Datafeed = {
  onReady: (callback) => {
    setTimeout(() => {
      callback(configurationData);
    }, 0);
  },
  searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
    // console.log('[searchSymbols]: Method call');
    // console.log(userInput)
    let { analysisData: {symbols} } = store.getState();
    let newSymbols = symbols.filter(symbol => {
			const isFullSymbolContainsInput = symbol.toLowerCase()
				.indexOf(userInput.toLowerCase()) !== -1;
			return isFullSymbolContainsInput
		});

    newSymbols = newSymbols.map(s => createSymbolInfo(s))
		onResultReadyCallback(newSymbols);
    // console.log(symbols, newSymbols)
  },

  resolveSymbol: (symbolName, onResolve, onError) => {
    let symbolInfo = createSymbolInfo(symbolName)

    new Promise((resolve) => {
      resolve(symbolInfo);
    })
      .then((resp) => onResolve(resp))
      .catch(onError);
  },

  getBars: (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback
  ) => {
    let { analysisData: {data} } = store.getState();
    // console.log(symbolInfo.name)
    let bars = mapData(data[symbolInfo.name])
    // console.log(bars)
    const { from, to } = periodParams;
    // console.log(from, to)
    bars = bars.filter(bar => bar.time >= from*1000 && bar.time < to*1000)
    // console.log(bars)
    onHistoryCallback(bars, { noData: false })
  },

  // getMarks: (symbolInfo, startDate, endDate, onDataCallback) => {
    // getTradeList().then((data) => {
    //   const markers = data.items.map((t) => createMarker(t));
    //   onDataCallback(markers);
    // });
  // },

  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback
  ) => {},
  unsubscribeBars: (subscriberUID) => {},
};