import NoData from '../../components/DataTable/NoData/NoData';
import Table from '../../components/DataTable/Table/Table';
import Filters from '../../components/DataTable/Filters/Filters';
import { useDispatch, useSelector } from "react-redux";
import styles from './dataTable.module.scss'
import { useNavigate } from 'react-router-dom';
import { analysisDataSelector, fetchAnalysisData } from '../../redux/analysis';
import Notification from '../../components/DataTable/Notification/Notification';
import { tableDataSelector } from '../../redux/table';
import Loader from '../../components/reusable/Loaders/Loader';

function DataTable() {
  const { data, selectedRowId, loading: loadingTable, report } = useSelector(tableDataSelector)
  const { loading: loadingCharts } = useSelector(analysisDataSelector)
  const dispatch = useDispatch()
  // console.log(loadingTable, loadingCharts)

  let navigate = useNavigate();
  const handleAnalyze = async () => {
    if (!selectedRowId && selectedRowId !== 0) {
      alert('Choose a row!')
      return
    }
    let res = await dispatch(fetchAnalysisData()).unwrap()
    if (res) navigate('/analysis')
  }

  return (
    <div className={styles.dataTable}>
      <Filters />
      {/* data == null -> <NoData />, data.length == 0 -> empty table */}
      { loadingTable
        ? <Loader wrapperClass="table-loader" />
        : data === null
          ? <NoData />
          : 
          <>
            <Table />
            <div className="table-footer">
              { loadingCharts
                ? <Loader wrapperClass="charts-loader" height={30} width={30} />
                : <button onClick={handleAnalyze} className="btn btn-green" type="button">Analyze</button>
              }
            </div>
            { report?.tickers && <Notification /> }
          </>
      }
    </div>
  )
}

export default DataTable