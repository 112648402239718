import { useSelector } from 'react-redux';
import {
  setSelectedRowId,
  tableDataSelector,
  applyFilter,
  clearFilter,
  setFilter,
  setRangeFilter,
  setSort,
} from '../../../redux/table';
import CustomTable from '../../reusable/CustomTable/CustomTable';

const Table = () => {
  const { data, selectedRowId } = useSelector(tableDataSelector);

  const cols = [
    {
      name: '',
      label: '',
      isRadioButton: true,
      filterOn: false,
    },
    {
      name: 'First Leg',
      isNum: false,
    },
    {
      name: 'Second Leg',
      isNum: false,
    },
    {
      name: 'Hedge Ratio',
      isNum: true,
    },
    {
      name: 'Hurst Exponent',
      isNum: true,
    },
    {
      name: 'Half Life',
      isNum: true,
    },
    {
      name: 'ADF T-value',
      isNum: true,
    },
    {
      name: 'ADF 99% P-value',
      isNum: true,
    },
    {
      name: 'ADF 95% P-value',
      isNum: true,
    },
    {
      name: 'ADF 90% P-value',
      isNum: true,
    },
    {
      name: 'Pearson Correlation',
      isNum: true,
    },
  ];

  let reducers = {
    dataSelector: tableDataSelector,
    applyFilter,
    clearFilter,
    setFilter,
    setRangeFilter,
    setSort,
  };

  return (
    <CustomTable
      data={data}
      cols={cols}
      selectedRowId={selectedRowId}
      setSelectedRowId={setSelectedRowId}
      reducers={reducers}
    />
  );
};

export default Table;
