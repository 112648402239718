import { call, fork, take } from 'typed-redux-saga';

import { login } from '../../../api/auth';
import asyncEntity from '../../../utils/redux/asyncEntity';

import { authActions } from '.';

const loginRequest = asyncEntity(authActions.loginAsync, (data) => login(data));

function* watchLogin() {
  while (true) {
    const { payload } = yield take(authActions.login);

    yield fork(loginRequest, payload);
  }
}

const getSagas = () => [call(watchLogin)];

export default getSagas;
