import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { tradingRulesSelector } from '../../../../redux/tradingRules'
import { Datafeed } from './datafeed'

function TvSpread() {
  const widgetRef = useRef(null)
  const tvRef = useRef()
  const { spread_symbols } = useSelector(tradingRulesSelector)

  useEffect(() => {
    const widgetOptions = {
      // preset: "mobile",
      // BEWARE: no trailing slash is expected in feed URL
      // datafeed: new window.Datafeeds.UDFCompatibleDatafeed("https://demo_feed.tradingview.com"),
      datafeed: Datafeed,
      symbol: spread_symbols[0],
      interval: "D",
      container: tvRef.current,
      fullscreen: true,
      autosize: false,
      library_path: "/js/charting_library/",
      overrides: {
        "paneProperties.background": "#222222",
        "paneProperties.vertGridProperties.color": "#454545",
        "paneProperties.horzGridProperties.color": "#454545",
        "scalesProperties.textColor": "#AAA",
        // "mainSeriesProperties.style": 2,
      },
    }
  
    widgetRef.current = new window.TradingView.widget(widgetOptions)
    // widgetRef.current.activeChart().setChartType(2)
  
    return () => {
      if (widgetRef.current !== null) {
        widgetRef.current.remove()
        widgetRef.current = null
      }
    }
  }, [spread_symbols])
  

  return (
    <div className='tv-chart'>
      <span className="chart-name">Spread plot</span>
      <div ref={tvRef} className={"TVChartContainer"} />
    </div>
  )
}

export default TvSpread
