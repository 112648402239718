import { Outlet } from 'react-router-dom'
import styles from './dashboard.module.scss'

function Dashboard() {
  return (
    <div className={styles.dashboard}>
      <Outlet />
    </div>
  )
}

export default Dashboard