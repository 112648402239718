import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { getRulesData } from '../api/tradingRules';
import { filterReducers, initFilterState } from '../components/reusable/CustomTable/reducers';
import { mapEquityData } from '../components/TradingRules/TvCharts/mappers';

export const fetchRulesData = createAsyncThunk('fetchRulesData', async (_, thunkAPI) => {
  let {
    tableData: { dates, tickersPair, data },
    tradingRules: { transCosts },
  } = thunkAPI.getState();
  thunkAPI.dispatch(setInitTransCosts());

  const hedge_ratio = data.find((d) => d['First Leg'] === tickersPair.split(',')[0])['Hedge Ratio'];

  let params = {
    hedge_ratio,
    end: dates.to,
    start: dates.from,
    tickers: tickersPair,
    transactions_costs: transCosts,
  };
  return await getRulesData(params);
});

const initSpreadSymbols = ['spread'];

export const tradingRulesSlice = createSlice({
  name: 'tradingRules',
  initialState: {
    initData: null,
    data: null,
    initTransCosts: 0.001,
    transCosts: 0.001,
    transCostsDiff: 0,
    isTableActual: false,
    loading: false,
    selectedRowId: 0,
    ...initFilterState,
    spread_symbols: initSpreadSymbols,
    equity_symbols: ['return'],
    trade_logs: [],
    tv_spread: {},
    init_tv_equity: [],
    tv_equity: [],
    all_equity: [],
  },
  reducers: {
    setInitTransCosts: (state, action) => {
      state.initTransCosts = state.transCosts;
      state.transCostsDiff = 0;
    },
    setTransCosts: (state, action) => {
      state.isTableActual = false;
      state.transCosts = action.payload;
      let transCostsDiff = state.initTransCosts - state.transCosts;
      state.transCostsDiff = transCostsDiff;
      // if (transCostsDiff === 0) state.tv_equity = state.init_tv_equity;
      // else state.tv_equity = equityNewTransCosts(current(state).init_tv_equity, transCostsDiff);
    },
    setSelectedRowId: (state, action) => {
      const currentState = current(state);

      const newId = action.payload;
      state.selectedRowId = newId;

      const equity_index = currentState.data[newId].Index - 1;

      const mappedEquity = mapEquityData(currentState.all_equity[equity_index]);
      state.tv_equity = mappedEquity;
    },
    setIsTrDataActual: (state, action) => {
      state.isTableActual = action.payload;
    },
    ...filterReducers(false),
  },
  extraReducers: {
    [fetchRulesData.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [fetchRulesData.fulfilled]: (state, { payload }) => {
      // console.log(payload)
      state.loading = false;
      state.isTableActual = true;
      state.initData = payload?.trade_rules;
      state.data = payload?.trade_rules;
      state.tv_spread = payload?.price_plot_data;
      if (payload?.price_plot_data) state.spread_symbols = Object.keys(payload.price_plot_data).reverse();
      else state.spread_symbols = initSpreadSymbols;
      state.trade_logs = payload?.trade_logs;

      const equityData = mapEquityData(payload.equity_curve[state.selectedRowId]);
      state.init_tv_equity = equityData;
      state.tv_equity = equityData;
      state.all_equity = payload.equity_curve;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setInitTransCosts,
  setTransCosts,
  setSelectedRowId,
  setFilter,
  setRangeFilter,
  setSort,
  clearFilter,
  clearAllFilters,
  applyFilter,
  setIsTrDataActual,
} = tradingRulesSlice.actions;

export const tradingRulesSelector = (state) => state.tradingRules;

export const selectTradingRuleByIndex = (state, index) => (index === null ? null : state.tradingRules.data[index]);

export default tradingRulesSlice.reducer;
