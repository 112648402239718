import { getType } from 'typesafe-actions';

import { authActions } from '.';

const initialState = {
  user: null,
  error: null,
  isError: false,
  isPending: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case getType(authActions.loginAsync.request): {
      return {
        ...state,
        params: action.payload,
      };
    }
    case getType(authActions.loginAsync.success): {
      const user = action.payload || { userName: 'Viktor' };

      return {
        ...state,
        user,
      };
    }
    case getType(authActions.loginAsync.failure): {
      return {
        ...state,
        error: action.payload,
      };
    }
    case getType(authActions.logout): {
      return {
        ...state,
        user: null,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
