import { createAction, createAsyncAction } from 'typesafe-actions';

export const createOrUpdateList = createAction('CREATE_OR_UPDATE_LIST')();
export const createOrUpdateListAsync = createAsyncAction(
  'CREATE_OR_UPDATE_LIST_REQUEST',
  'CREATE_OR_UPDATE_LIST_SUCCESS',
  'CREATE_OR_UPDATE_LIST_ERROR'
)();

export const fetchTradingList = createAction('FETCH_TRADING_LIST')();
export const fetchTradingListAsync = createAsyncAction(
  'FETCH_TRADING_LIST_REQUEST',
  'FETCH_TRADING_LIST_SUCCESS',
  'FETCH_TRADING_LIST_ERROR'
)();

export const deleteTradingList = createAction('DELETE_TRADING_LIST')();
export const deleteTradingListAsync = createAsyncAction(
  'DELETE_TRADING_LIST_REQUEST',
  'DELETE_TRADING_LIST_SUCCESS',
  'DELETE_TRADING_LIST_ERROR'
)();

export const saveRule = createAction('SAVE_RULE')();
export const saveRuleAsync = createAsyncAction('SAVE_RULE_REQUEST', 'SAVE_RULE_SUCCESS', 'SAVE_RULE_ERROR')();

export const deleteRule = createAction('DELETE_RULE')();
export const deleteRuleAsync = createAsyncAction('DELETE_RULE_REQUEST', 'DELETE_RULE_SUCCESS', 'DELETE_RULE_ERROR')();
