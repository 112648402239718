import { TextField, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

import Button from '../../Button';
import Modal from '../../Modal';
import { tradingListActions } from '../../../redux/modules/trading-list';

import styles from './index.module.scss';

const INIT_NAME = '';

const NewTabModal = ({ isOpen, onClose, onCreateTab }) => {
  const dispatch = useDispatch();
  const [text, setText] = useState(INIT_NAME);

  const handelNewListChange = (e) => setText(e.target.value.slice(0, 10));

  const handelCreateList = () => {
    dispatch(tradingListActions.createOrUpdateList({ name: text }));
    onClose();
    setText(INIT_NAME);
  };

  return (
    <Modal open={isOpen} onClose={onClose} keepMounted={false}>
      <div>
        <Typography variant="h5" className={styles.heading}>
          New trading list
        </Typography>
        <label className={styles.fieldLabel}>
          <span>Name of new list</span>
          <TextField
            autoFocus
            variant="outlined"
            placeholder="Trading list 5"
            helperText="Up to 10 symbols in the list's name"
            value={text}
            onChange={handelNewListChange}
          />
        </label>
        <div className={styles.buttons}>
          <Button onClick={onClose}>Cancel</Button>
          <Button variant="green" onClick={handelCreateList}>
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default NewTabModal;
