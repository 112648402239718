import { current } from '@reduxjs/toolkit';

import { sortTableData } from '../../../utils/sorting';

export const initFilterState = {
  filter: {},
  rangeFilter: {},
  sort: {},
};

export const filterReducers = (isMainTable) => ({
  setFilter: (state, { payload }) => {
    let { name, filterType, value } = payload;
    let filter = current(state).filter;
    state.filter = {
      ...filter,
      [name]: {
        [filterType]: value,
      },
    };
    console.log(current(state).filter);
  },
  setRangeFilter: (state, { payload }) => {
    let { name, filterType, value } = payload;
    let rangeFilter = current(state).rangeFilter;
    state.rangeFilter = {
      ...rangeFilter,
      [name]: {
        ...rangeFilter[name],
        [filterType]: value,
      },
    };
    console.log(current(state).rangeFilter);
  },
  setSort: (state, { payload }) => {
    let { name, sortType } = payload;
    state.sort = {
      [name]: sortType,
    };
    console.log(current(state).sort);
  },
  clearFilter: (state, { payload }) => {
    console.log(payload);
    delete state.filter[payload];
    delete state.rangeFilter[payload];
    state.sort = {};
    console.log(current(state).data);
  },
  clearAllFilters: (state, { payload }) => {
    state.data = state.initData;
    state.filter = {};
    state.rangeFilter = {};
    state.sort = {};
  },
  applyFilter: (state, { payload }) => {
    let currentState = current(state);

    const oldIndex = currentState.selectedRowId;
    const oldTradeID = currentState.data[oldIndex]?.ID;
    const oldTableSelectedFirstLeg = currentState.data[oldIndex]?.['First Leg'];

    let data = currentState.initData;
    // console.log(data)
    if (data.length < 1) return;
    let filter = currentState.filter;
    let rangeFilter = currentState.rangeFilter;
    let sort = currentState.sort;
    let key = Object.keys(currentState.sort)[0];
    if (key) data = sortTableData({ data, key, filterType: sort[key] });
    // console.log(data)
    if (data.length) {
      Object.keys(filter).forEach((name) => {
        let filterType = Object.keys(filter[name])[0];
        data = sortTableData({
          data,
          key: name,
          filterType: filterType,
          value: filter[name][filterType],
        });
      });
    }
    // console.log(data)
    if (data.length) {
      Object.keys(rangeFilter).forEach((name) => {
        data = sortTableData({
          data,
          key: name,
          filterType: 'range',
          value: rangeFilter[name]['from'],
          value2: rangeFilter[name]['to'],
        });
      });
    }
    const cb = isMainTable ? (t) => t['First Leg'] === oldTableSelectedFirstLeg : (t) => t.ID === oldTradeID;
    const newIndex = data.findIndex(cb);

    state.data = data;
    state.selectedRowId = newIndex;
  },
});
