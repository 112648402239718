import { useEffect, useRef, useState } from 'react';

import NewTabModal from './NewTabModal';
import ConfirmDelete from '../ConfirmDelete';
import styles from './index.module.scss';

const Tabs = ({ children, tabsList, initTab = tabsList?.[0]?.id, onTabChange, onDelete, emptyText }) => {
  const [activeTabId, setActiveTabId] = useState(initTab);
  const tabIndex = tabsList.findIndex((t) => t.id === activeTabId) || 0;
  const currentView = Array.isArray(children) ? children[tabIndex] : children;

  useEffect(() => {
    setActiveTabId(initTab);
  }, [initTab]);

  const [isOpenAddModal, setOpenAddModal] = useState(false);
  const handelOpenAddModal = () => setOpenAddModal(true);
  const handelCloseAddModal = () => setOpenAddModal(false);

  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const handelOpenDeleteModal = () => setOpenDeleteModal(true);
  const handelCloseDeleteModal = () => setOpenDeleteModal(false);

  const tabToDelete = useRef(null);
  const handelDeleteList = () => {
    onDelete(tabToDelete.current?.id);
    handelCloseDeleteModal();
  };

  return (
    <div className={styles.wrapper}>
      <NewTabModal isOpen={isOpenAddModal} onClose={handelCloseAddModal} />
      <ConfirmDelete
        open={isOpenDeleteModal}
        onClose={handelCloseDeleteModal}
        onDelete={handelDeleteList}
        listName={tabToDelete.current?.name}
        description={`Deleting this list is permanent and cannot be reversed. Are you sure you wish to delete <b>${tabToDelete.current?.name}</b> and content?`}
      />
      <div className={styles.header}>
        {tabsList.map((tab, i) => (
          <div
            key={tab.name + i}
            onClick={() => {
              setActiveTabId(tab.id);
              onTabChange(tab);
            }}
            className={`${tab.id === activeTabId && styles.activeTab} ${styles.tabItem}`}
          >
            {onDelete && tab.id === activeTabId && (
              <img
                alt="delete list"
                src="/images/ic-delete-list.svg"
                onClick={(e) => {
                  e.stopPropagation();
                  handelOpenDeleteModal();
                  tabToDelete.current = tab;
                }}
              />
            )}
            {tab.name}
          </div>
        ))}
        {tabsList.length < 10 && (
          <div onClick={handelOpenAddModal} className={styles.addTab}>
            +
          </div>
        )}
      </div>
      <div className={styles.content}>
        {currentView}
        {tabsList.length === 0 && <p>{emptyText}</p>}
      </div>
    </div>
  );
};

export default Tabs;
