import { useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { useDispatch } from 'react-redux';

import { tradingListActions } from '../../../redux/modules/trading-list';
import ConfirmDelete from '../../ConfirmDelete';

import styles from './index.module.scss';

const CollapseRow = ({ row, nestedHeader }) => {
  const dispatch = useDispatch();
  const [isOpenRow, setOpenRow] = useState(false);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDD, setOpenDD] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenDD((prev) => !prev);
  };

  const handelDeleteRule = () => {
    dispatch(tradingListActions.deleteRule({ id: row.id.toLowerCase() }));
    setOpenDeleteModal(false);
  };

  return (
    <>
      <ConfirmDelete
        open={isOpenDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onDelete={handelDeleteRule}
        description={`Deleting this rule <b>${row.id}</b> is permanent and cannot be reversed. Are you sure you wish to delete it?`}
      />
      <TableRow sx={{ '& > *': { padding: 0, height: 40 }, background: isOpenRow ? '#EDF5F0' : 'transparent' }}>
        <TableCell sx={{ width: 40, height: 40, padding: 0 }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpenRow(!isOpenRow)}>
            {isOpenRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.spread}
        </TableCell>
        <TableCell align="left">{row.id}</TableCell>
        <TableCell align="left">{row.date}</TableCell>
        <TableCell align="left">
          <span className={`${!!row.in_position && styles.badgePositiveSignals} ${styles.badgeNoSignals}`}>
            {row.in_position ? 'In position' : 'Not in position'}
          </span>
        </TableCell>
        {/* <TableCell align="left" sx={{ display: 'flex', alignItems: 'center' }}>
          <span className={styles.revenue}>{row.revenue ? `${row.revenue}%` : '-'}</span>
          {!!row.in_position && (
            <span className={`${row.trend > 0 ? styles.upTrend : styles.downTrend} ${styles.trendLabel}`}>
              <img src={`/images/${row.trend > 0 ? 'up_trend' : 'down_trend'}.svg`} alt="trend" />
              {row.trend ? `${row.trend}%` : '-'}
            </span>
          )}
        </TableCell> */}
        <TableCell>
          <ClickAwayListener onClickAway={() => setOpenDD(false)}>
            <div className={styles.dropdownBox}>
              <img src="/images/dots-horizontal.svg" alt="dropdown" onClick={handleClick} />
            </div>
          </ClickAwayListener>
          <Popper open={openDD} anchorEl={anchorEl} placement={'bottom-end'} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper sx={{ padding: '8px', width: 132 }}>
                  <span
                    className={`${styles.deleteRule} ${row.in_position && styles.disabled}`}
                    onClick={() => !row.in_position && setOpenDeleteModal(true)}
                  >
                    <img src="/images/ic-delete.svg" alt="delete rule" />
                    Delete rule
                  </span>
                </Paper>
              </Fade>
            )}
          </Popper>
        </TableCell>
      </TableRow>
      <TableRow sx={{ '& > td': { padding: '0 !important' } }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse sx={{ background: '#EDF5F0', padding: '10px 20px' }} in={isOpenRow} timeout="auto" unmountOnExit>
            <Box sx={{ background: '#EDF5F0' }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow sx={{ '& > th': { height: 40, padding: '0 8px' } }}>
                    {nestedHeader.map((h, i) => (
                      <TableCell key={h.label + i}>{h.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    background: '#fff',
                    '& > tr': { height: 40, padding: '0 8px', fontWeight: 600, fontSize: 12, lineHeight: 16 },
                  }}
                >
                  {row.history.map((r, i) => (
                    <TableRow key={r.id + i}>
                      {nestedHeader.map((h, i) => (
                        <TableCell key={h.label + i}>{+r[nestedHeader[i].key].toFixed(3)}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CollapseRow;
