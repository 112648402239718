export const createRowData = ({
  id,
  leg_1,
  leg_2,
  sharpe,
  sl_sigma,
  created_on,
  in_position,
  hedge_ratio,
  z_score_entry,
  z_score_exit_delta,
  total_return,
}) => ({
  in_position,
  id: id.toUpperCase(),
  date: new Date(created_on).toLocaleDateString(),
  trend: 0.6,
  spread: `${leg_1}/${leg_2}`,
  revenue: 8,
  history: [
    {
      hedge_ratio,
      entry: z_score_entry,
      exit: z_score_exit_delta,
      sigma: sl_sigma,
      return: total_return,
      sharpe_ratio: sharpe,
    },
  ],
});

export const getTableHeaders = () => {
  const header = ['', 'Spread', 'Trading rule ID', 'Date', 'Status', ''];
  const nestedHeader = [
    { label: 'Z-score entry', key: 'entry' },
    { label: 'Z-score exit', key: 'exit' },
    { label: 'SL Sigma', key: 'sigma' },
    { label: 'Return', key: 'return' },
    { label: 'Sharpe Ratio', key: 'sharpe_ratio' },
    { label: 'Hedge Ratio', key: 'hedge_ratio' },
  ];

  return [header, nestedHeader];
};
