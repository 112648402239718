import { useSelector } from 'react-redux';
import Buttons from '../../components/Analysis/Buttons/Buttons';
import Charts from '../../components/Analysis/Charts/Charts';
import Head from '../../components/Analysis/Head/Head';
import Statistics from '../../components/Analysis/Statistics/Statistics';
import styles from './analysis.module.scss';
import { Navigate } from 'react-router-dom';
import { analysisDataSelector } from '../../redux/analysis';
import Loader from '../../components/reusable/Loaders/Loader';

function Analysis() {
  const { loading: loadingCharts, data } = useSelector(analysisDataSelector);

  if (!data) return <Navigate to="/table" />;
  return (
    <div className={styles.analysis}>
      <Head title="Analysis of" />
      <div className={styles.content}>
        {loadingCharts ? (
          <Loader wrapperClass="analysis-loader" />
        ) : (
          <>
            <Statistics />
            <Charts />
            <Buttons />
          </>
        )}
      </div>
    </div>
  );
}

export default Analysis;
