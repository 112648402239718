import { all } from 'typed-redux-saga';

import { tradingListSaga } from './modules/trading-list';
import { authSaga } from './modules/auth';

export const createSagas = () => [...tradingListSaga(), ...authSaga()];

function* mainSaga() {
  try {
    yield* all(createSagas());
  } catch (e) {
    console.error('saga error: ', e);
  }
}

export default mainSaga;
