import { useState, ChangeEvent, useCallback } from 'react';

// const isValidEmail = (val) => /^.+@.+\..+$/.test(val);
const isValidEmail = (val) => true;

export const useEmailControl = () => {
  const [email, setEmail] = useState('');
  const [isEmailError, setEmailError] = useState(false);

  const validateEmail = useCallback((val) => {
    setEmailError(!isValidEmail(val));
  }, []);

  const handelChangeEmail = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    const emailValue = target.value;
    setEmail(emailValue);

    const isValid = isValidEmail(emailValue);
    isValid && setEmailError(false);
  }, []);

  return [email, isEmailError, handelChangeEmail, validateEmail];
};

export const usePasswordControl = () => {
  const [password, setPassword] = useState('');

  const handelPasswordChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setPassword(target.value);
  };

  return [password, handelPasswordChange];
};
