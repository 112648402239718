import { useNavigate } from 'react-router-dom';
import styles from './head.module.scss';
import { useDispatch, useSelector } from 'react-redux';
// import { fetchAnalysisData } from '../../../redux/analysis';
import {  tableDataSelector } from '../../../redux/table';
import DateRangePicker from '../../reusable/DateRangePicker';
import TransactionsCosts from '../../reusable/TransactionsCosts/TransactionsCosts';
import { fetchRulesData, tradingRulesSelector } from '../../../redux/tradingRules';

function Head({ title }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data, selectedRowId } = useSelector(tableDataSelector);
  const { isTableActual, loading } = useSelector(tradingRulesSelector);

  // const handleChange = (e) => {
  //   dispatch(setSelectedRowId(e.target.value));
  //   dispatch(fetchAnalysisData());
  // };

  const handleOptimize = (e) => {
    e.preventDefault();
    dispatch(fetchRulesData());
    navigate('/trading-rules');
  };

  const getPairLabel = () => {
    const row = data?.[selectedRowId];

    if (!row) return '';

    return `${row['First Leg']} / ${row['Second Leg']}`;
  };

  return (
    <div className={styles.head}>
      <img onClick={() => navigate(-1)} className="back" src="/images/arr-back.svg" alt="" />
      <div className="tickers">
        <span>
          {title} {getPairLabel()}
        </span>
        {/* {data && (
          <FormControl variant="standard" sx={{ ml: 1, minWidth: 100 }}>
            <Select
              id="legs-select"
              value={selectedRowId}
              onChange={handleChange}
              sx={{
                color: '#52956A',
                fontSize: 20,
                fontFamily: 'Mulish',
                fontWeight: 700,
                '&:after': { border: 'none!important' },
                '&:before': { border: 'none!important' },
              }}
              MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
            >
              {data.map((i, id) => (
                <MenuItem key={'item' + id} value={id}>{`${i['First Leg']} / ${i['Second Leg']}`}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )} */}
      </div>

      <form className="params" onSubmit={handleOptimize}>
        <TransactionsCosts />
        <div className="dates">
          <DateRangePicker />
        </div>
        <button disabled={isTableActual || loading} type="submit" className="btn btn-yellow">
          Optimize
        </button>
      </form>
    </div>
  );
}

export default Head;
