import { TextField } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { setTransCosts, tradingRulesSelector } from "../../../redux/tradingRules"

function TransactionsCosts() {
  const { transCosts } = useSelector(tradingRulesSelector)
  const dispatch = useDispatch()

  return (
    <TextField
      value={transCosts}
      onChange={e => dispatch(setTransCosts(e.target.value))}
      inputProps={{step: 0.001, min: 0, max: 100}}
      label="Transaction costs, %"
      type="number" variant="outlined"
      sx={{width: 118}}
      InputLabelProps={InputLabelStyles}
    />
  )
}

export default TransactionsCosts

const InputLabelStyles = {
  sx: {
    fontSize: 14,
    transform: 'translate(9px, -7px) scale(0.75)',
  }
}