import { TextField, Select, OutlinedInput, MenuItem } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';

import Modal from '../../../Modal';
import Button from '../../../Button';
import { selectTradingRuleByIndex } from '../../../../redux/tradingRules';
import { tradingListSelectors, tradingListActions } from '../../../../redux/modules/trading-list';

import styles from './index.module.scss';

const AddRuleModal = ({ ruleIndex, ...props }) => {
  const dispatch = useDispatch();
  const [selectedList, setSelectedList] = useState('');
  const lists = useSelector(tradingListSelectors.selectTradingLists);
  const rule = useSelector((state) => selectTradingRuleByIndex(state, ruleIndex));

  const handelListChange = (event) => setSelectedList(event.target.value);

  const handelAddRule = () => {
    selectedList
      ? dispatch(tradingListActions.saveRule({ ruleIndex, listId: selectedList }))
      : dispatch(tradingListActions.createOrUpdateList({ name: newList, ruleIndex }));

    props.onClose();
  };

  const [newList, setNewList] = useState('');

  const handelNewListChange = (e) => setNewList(e.target.value.slice(0, 10));

  return (
    <Modal {...props}>
      <>
        <p className={styles.title}>Add rules to trading list</p>
        <p>
          Selected rule id <b>{rule?.ID}</b>
        </p>
        <label className={styles.label}>Select trading list</label>
        <Select
          displayEmpty
          disabled={lists.length === 0}
          style={{ width: '100%' }}
          value={selectedList}
          input={<OutlinedInput />}
          onChange={handelListChange}
        >
          {lists.map((l) => (
            <MenuItem key={l.id} value={l.id} name={l.name}>
              {l.name}
            </MenuItem>
          ))}
        </Select>
        <p className={styles.dividerText}>or add a new trading list</p>
        <label className={styles.label}>Name of new list </label>
        <TextField
          style={{ width: '100%' }}
          variant="outlined"
          placeholder="TradingList"
          helperText="Up to 10 symbols in the list's name"
          value={newList}
          onChange={handelNewListChange}
        />
        <div>
          <div className={styles.buttons}>
            <Button onClick={props.onClose}>Close</Button>
            <Button variant="green" onClick={handelAddRule} disabled={!selectedList && !newList}>
              Add rule
            </Button>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default AddRuleModal;
