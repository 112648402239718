import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { analysisDataSelector } from "../../../redux/analysis";

function PlotlyCharts() {
  const { plots_config } = useSelector(analysisDataSelector)
  window.chartIds = ['correlation','pacf','qq']
  let chart0 = plots_config[window.chartIds[0]]
  let chart1 = plots_config[window.chartIds[1]]
  let chart2 = plots_config[window.chartIds[2]]

  return (
    <Helmet>
      <script>
        {`
          window.chartIds.forEach((id, i) => {
            let chart, layout;
            if (i === 0) chart = ${JSON.stringify(chart0)}
            else if (i === 1) chart = ${JSON.stringify(chart1)}
            else if (i === 2) chart = ${JSON.stringify(chart2)}

            layout = chart.layout
            layout.autosize = true
            layout.width = document.querySelectorAll('.chart')[i+1].clientWidth
            layout.height = 400
            Plotly.newPlot(
              document.getElementById(id),
              chart.data,
              layout,
            );
          })
        `}
      </script>
    </Helmet>
  )
}

export default PlotlyCharts