import { useState, useEffect } from 'react';

import { getHistory } from '../../api/history';

const History = () => {
  const [historyCVS, setHistory] = useState('');

  useEffect(() => {
    (async () => {
      const h = await getHistory();

      setHistory(h.history_csv);
    })();
  }, []);

  return (
    <>
      <h3>History</h3>
      <p>{historyCVS}</p>
    </>
  );
};

export default History;
