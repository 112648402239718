import { createSelector } from 'reselect';

export const selectTradingList = (state) => state.tradingList;
export const selectTradingListData = createSelector(selectTradingList, (list) => list.data);

export const selectTradingListRules = createSelector(selectTradingListData, (data) => data.rules);
export const selectTradingLists = createSelector(selectTradingListData, (list) => list.lists);
export const selectRulesByListId = createSelector(
  selectTradingListRules,
  (_, props) => props,
  (rules, listId) => rules.filter((r) => r.list_id === listId)
);

export const selectLastListUpdatedTime = createSelector(
  selectTradingLists,
  (lists) => lists[lists.length - 1]?.created_on
);
export const selectLastRuleUpdatedTime = createSelector(
  selectTradingListRules,
  (rules) => rules[rules.length - 1]?.created_on
);

export const selectCreateListParams = createSelector(selectTradingList, (list) => list.createList.params);
