import { useSelector } from 'react-redux';
import { useState } from 'react';

import {
  setSelectedRowId,
  tradingRulesSelector,
  applyFilter,
  clearFilter,
  setFilter,
  setRangeFilter,
  setSort,
} from '../../../redux/tradingRules';
import CustomTable from '../../reusable/CustomTable/CustomTable';
import useFetchTradingList from '../../../hooks/useFetchTradingList';

import AddRuleModal from './AddRuleModal';

const Table = () => {
  useFetchTradingList();
  const { data, selectedRowId } = useSelector(tradingRulesSelector);

  const cols = [
    {
      name: '',
      label: '',
      filterOn: false,
      isRadioButton: true,
    },
    {
      name: '',
      label: '№',
      filterOn: false,
    },
    {
      name: 'Z-score entry',
      label: 'Z-entry',
      info: 'Z-score entry',
      isNum: true,
    },
    {
      name: 'Z-score exit',
      label: 'Z-exit',
      info: 'Z-score exit',
      isNum: true,
    },
    {
      name: 'SL sigma',
      label: 'SL',
      info: 'Stop-Loss sigma',
      isNum: true,
    },
    {
      name: 'Return',
      label: 'Return',
      isNum: true,
      toFixed: 1,
      info: 'Return, %',
    },
    {
      name: 'Sharpe Ratio',
      label: 'Sharpe',
      info: 'Sharpe Ratio',
      isNum: true,
    },
    {
      name: 'Days in position',
      label: 'Max. days',
      info: 'Maximum days in position',
      isNum: true,
      toFixed: 0,
    },
    {
      name: 'Estimation Window',
      label: 'EW',
      info: 'Estimation window, observations',
      isNum: true,
      toFixed: 0,
    },
    {
      name: 'Max drawdown',
      label: 'DD',
      info: 'Maximum drawdown,%',
      isNum: true,
      toFixed: 2,
    },
    {
      name: 'Add',
      filterOn: false,
    },
  ];

  let reducers = {
    dataSelector: tradingRulesSelector,
    applyFilter,
    clearFilter,
    setFilter,
    setRangeFilter,
    setSort,
  };

  const [isOpen, setOpenModal] = useState(false);
  const handelCloseModal = () => setOpenModal(false);

  const [ruleIndex, setRuleIndex] = useState(null);

  const handelOpenModal = (rowIndex) => {
    setOpenModal(true);
    setRuleIndex(rowIndex);
  };

  const mappedData = (data || []).map((d) => ({ ...d, Return: d.Return * 100 }));

  return (
    <>
      <AddRuleModal ruleIndex={ruleIndex} open={isOpen && ruleIndex !== null} onClose={handelCloseModal} />
      <CustomTable
        cols={cols}
        data={mappedData}
        onOpenModal={handelOpenModal}
        selectedRowId={selectedRowId}
        setSelectedRowId={setSelectedRowId}
        reducers={reducers}
        maxHeight="800px"
      />
    </>
  );
};

export default Table;
