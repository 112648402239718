import MUIDataTable from 'mui-datatables';
import { useDispatch } from 'react-redux';
import {
  customHeadRender,
  customBodyRender,
  renderRadioButton,
  setCellProps,
  rowNumberRender,
  renderSaveToList,
} from './customRenders';
import styles from './table.module.scss';

const CustomTable = ({
  cols,
  data,
  selectedRowId,
  setSelectedRowId,
  reducers,
  maxHeight = '600px',
  onOpenModal,
  extraOptions = {},
}) => {
  const dispatch = useDispatch();

  let columns = cols.map(({ name, label, info, isNum, toFixed, filterOn = true, isRadioButton = false }, index) => ({
    name,
    label,
    options: {
      customHeadRender: customHeadRender(reducers, isNum, filterOn, info),
      setCellProps,
      customBodyRender: isRadioButton
        ? renderRadioButton(selectedRowId)
        : label === '№'
        ? rowNumberRender
        : isNum
        ? customBodyRender(toFixed)
        : !label
        ? renderSaveToList(onOpenModal)
        : null,
    },
  }));

  const options = {
    pagination: false,
    sort: false,
    filter: false,
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    draggableColumns: {
      enabled: false,
    },
    selectableRowsHideCheckboxes: true,
    selectableRows: 'single',
    selectableRowsOnClick: true,
    selectToolbarPlacement: 'none',
    responsive: 'standard',
    tableBodyMaxHeight: maxHeight,
    rowsSelected: [selectedRowId],
    // customHeadLabelRender: (...a: any) => {
    //   console.log('LLL 111 ', a);
    //   return <span>label</span>;
    // },
    // customHeadRender: (...a: any) => {
    //   console.log('LLL 22 ', a);
    //   return <span>label</span>;
    // },
    isRowSelectable: (dataIndex, selectedRows) => selectedRows.data[0].index !== dataIndex,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          // changePage()
          break;
        default:
        // console.log('action not handled.');
      }
    },
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected, ...r) => {
      dispatch(setSelectedRowId(rowsSelected[0]));
    },
    ...extraOptions,
  };

  return (
    <div className={styles.table}>
      <MUIDataTable data={data} columns={columns} options={options} />
    </div>
  );
};

export default CustomTable;
