import { Modal, Box } from '@mui/material';

import styles from './index.module.scss';

const CustomModal = ({ children, width = 400, ...props }) => (
  <Modal {...props}>
    <Box className={styles.content} sx={{ width }}>
      {children}
    </Box>
  </Modal>
);

export default CustomModal;
