export const sortTableData = ({data, key, filterType, value, value2}) => {
  let filteredData = []
  switch(filterType) {
    case 'az':
      filteredData = data.concat()
      filteredData.sort((i1, i2) => {
        let x = i1[key].toLowerCase()
        let y = i2[key].toLowerCase()
        if (x < y) {return -1;}
        if (x > y) {return 1;}
        return 0
      })
      break;
    case 'za':
      filteredData = data.concat()
      filteredData.sort((i1, i2) => {
        let x = i1[key].toLowerCase()
        let y = i2[key].toLowerCase()
        if (x > y) {return -1;}
        if (x < y) {return 1;}
        return 0
      })
      break;
    case 'asc':
      filteredData = data.concat()
      filteredData.sort((i1, i2) => {
        return i1[key] - i2[key]
      })
      break;
    case 'desc':
      filteredData = data.concat()
      filteredData.sort((i1, i2) => {
        return i2[key] - i1[key]
      })
      break;
    case 'gt':
      filteredData = data.filter(i => i[key] > value)
      break;
    case 'lt':
      filteredData = data.filter(i => i[key] < value)
      break;
    case 'et':
      filteredData = data.filter(i => +i[key].toFixed(3) === +value)
      break;
    case 'range':
      filteredData = data.filter(i => i[key] > value && i[key] < value2)
      break;
    default:
      // code block
  }
  return filteredData
}