import { baseUrl } from '../config';
import createAxiosRequest from '../utils/createAxiosRequest';

const request = createAxiosRequest({ baseURL: baseUrl });

export const createOrUpdateList = (data) =>
  request({
    url: `/bot/list`,
    method: 'PUT',
    data,
  });

export const getListAndData = (data) => request({ url: `/bot/data` });

export const deleteList = (id) =>
  request({
    url: '/bot/list',
    method: 'DELETE',
    data: { id },
  });

export const addRuleToList = (data) =>
  request({
    url: '/bot/rule',
    method: 'PUT',
    data,
  });

export const deleteRuleFromList = (data) =>
  request({
    url: '/bot/rule',
    method: 'DELETE',
    data,
  });

export const generateSignals = () =>
  request({
    url: '/bot/run',
    method: 'POST',
    data: {},
  });
