import styles from './header.module.scss';
import { Link, useLocation } from 'react-router-dom';

import { authSelectors, authActions } from '../../redux/modules/auth';
import { useDispatch, useSelector } from 'react-redux';

const Header = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const user = useSelector(authSelectors.selectUser);
  const handelLogout = () => {
    localStorage.clear();
    dispatch(authActions.logout());
  };

  return (
    <div className={styles.header}>
      {user && (
        <div className={styles.left_part}>
          <Link to="/">
            <img className={styles.logo} src="/images/header_logo.svg" alt="logo" />
          </Link>
          <Link to="/table">
            <div className={`${pathname === '/table' && styles.activeAction} ${styles.action}`}>Home</div>
          </Link>
          <Link to="trading-rules">
            <div className={`${pathname === '/trading-rules' ? styles.activeAction : ''} ${styles.action}`}>
              Trading rules
            </div>
          </Link>
          <Link to="/trading-list">
            <div className={`${pathname === '/trading-list' ? styles.activeAction : ''} ${styles.action}`}>
              Trading list
            </div>
          </Link>
          <Link to="/history">
            <div className={`${pathname === '/history' ? styles.activeAction : ''} ${styles.action}`}>History</div>
          </Link>
        </div>
      )}
      <div className={styles.right_part}>
        {user ? (
          <>
            <img src="/images/settings.svg" alt="settings" style={{ marginRight: '16px', cursor: 'pointer' }} />
            <Link to="/login">
              <img src="/images/logout.svg" alt="logout" onClick={handelLogout} />
            </Link>
          </>
        ) : (
          <>
            <Link to="/login">
              <div className={styles.action}>Log in</div>
            </Link>
            <Link to="/registration">
              <div className={styles.action}>Registration</div>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
