import { useNavigate } from 'react-router-dom';
import styles from './btns.module.scss'

function Buttons() {
  let navigate = useNavigate();
  
  return (
    <div className={styles.buttons}>
      <button onClick={() => navigate(-1)} type="button" className="btn btn-outline btn-green">Back</button>
      {/* <button disabled type="button" className="btn btn-green">Optimize</button> */}
    </div>
  )
}

export default Buttons