import dayjs from "dayjs"

export const formatDate = date => {
  return dayjs(date).format('YYYY-MM-DD')
}

export const valueOfDate = date => {
  return dayjs(date).valueOf()
}

export const unixDate = date => {
  return dayjs(date).unix()
}

export const sub1year = date => {
  return dayjs(date).subtract(1, 'year').format('YYYY-MM-DD')
}

export const add1year = date => {
  return dayjs(date).add(1, 'year').format('YYYY-MM-DD')
}