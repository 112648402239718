import { TextField, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import styles from './index.module.scss';
import { useEmailControl, usePasswordControl } from './hooks';
import { authActions, authSelectors } from '../../redux/modules/auth';
import { useEffect } from 'react';

const Login = () => {
  const dispatch = useDispatch();
  const [email, isEmailError, handelChangeEmail, validateEmail] = useEmailControl();
  const [password, handelPasswordChange] = usePasswordControl();
  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector(authSelectors.selectUser);

  useEffect(() => {
    if (!user) return;

    const from = location.state?.from?.pathname || '/';
    navigate(from, { replace: true });
  }, [user]);

  const onSubmit = (e) => {
    e.preventDefault();
    !isEmailError && dispatch(authActions.login({ username: email, password }));
  };

  return (
    <form className={styles.wrapper} onSubmit={onSubmit}>
      <TextField
        type="text"
        label="Email"
        variant="outlined"
        value={email}
        error={isEmailError}
        onChange={handelChangeEmail}
        // inputProps={{ pattern: '^.+@.+..+$' }}
        onBlur={() => validateEmail(email)}
        helperText={isEmailError ? 'Email not valid' : undefined}
      />
      <TextField type="password" label="Password" variant="outlined" value={password} onChange={handelPasswordChange} />
      <Button type="submit" variant="contained">
        Login
      </Button>
    </form>
  );
};

export default Login;
