import { useSelector } from 'react-redux';
import Head from '../../components/Analysis/Head/Head';
import Loader from '../../components/reusable/Loaders/Loader';
import Notification from '../../components/TradingRules/Notification/Notification';
import Table from '../../components/TradingRules/Table/Table';
import { tradingRulesSelector } from '../../redux/tradingRules';
import styles from './rules.module.scss';
import TvSpread from '../../components/TradingRules/TvCharts/Spread/Spread';
import TvEquity from '../../components/TradingRules/TvCharts/Equity/Equity';
import Buttons from '../../components/TradingRules/Buttons/Buttons';

function TradingRules() {
  const { loading, selectedRowId, data } = useSelector(tradingRulesSelector);

  const tradeId = data?.[selectedRowId]?.ID;

  return (
    <div className={styles.rules}>
      <Head title="Trading rules for" />
      {loading ? (
        <Loader wrapperClass="rules-loader" />
      ) : (
        <>
          <div className="rules-body">
            <Table />
            <div className="tv-charts">
              <TvSpread key={'spread' + tradeId} />
              <TvEquity key={'equity' + tradeId} />
            </div>
            <Notification />
          </div>
          <Buttons />
        </>
      )}
    </div>
  );
}

export default TradingRules;
