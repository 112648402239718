import styles from './charts.module.scss'
import TVchart from './TV/TV'
import PlotlyCharts from './PlotlyCharts'

function Charts() {
  return (
    <div className={styles.charts}>
      <div className="chart">
        <span>Tradingview plot</span>
        {/* <img src="/images/charts/tv.svg" alt="" /> */}
        <TVchart />
      </div>
      <div className="chart">
        <span>Returns plot</span>
	      <div id="correlation" className='plotly-chart'></div>
        {/* <img src="/images/charts/corr.jpg" alt="" /> */}
      </div>
      <div className="chart">
        <span>PACF plot</span>
	      <div id="pacf" className='plotly-chart'></div>
        {/* <img src="/images/charts/pacf.jpg" alt="" /> */}
      </div>
      <div className="chart">
        <span>Normal Q-Q plot</span>
	      <div id="qq" className='plotly-chart'></div>
        {/* <img src="/images/charts/qq.jpg" alt="" /> */}
      </div>

      <PlotlyCharts />
    </div>
  )
}

export default Charts