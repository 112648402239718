import styles from './filters.module.scss';
import fileIcon from './file.svg';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/system';
import CSVReader from 'react-csv-reader';
import { fetchTableData, setMethod, setTickers, tableDataSelector } from '../../../redux/table';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import DateRangePicker from '../../reusable/DateRangePicker';

function Filters() {
  const { fileName, method, report, loading } = useSelector(tableDataSelector);
  const dispatch = useDispatch();

  const handleChangeMethod = (e) => {
    dispatch(setMethod(e.target.value));
  };

  const handleFile = (data, { name }) => {
    console.log(data, name);
    let tickers = data.flat().join(',');
    dispatch(setTickers({ tickers, name }));
  };
  const papaparseOptions = {
    // header: true,
    // delimiter: ",",
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
  };

  const handleRun = (e) => {
    e.preventDefault();
    if (!fileName) {
      alert('Choose file!');
      return;
    }
    dispatch(fetchTableData());
  };

  return (
    <form onSubmit={handleRun} className={styles.filters}>
      <label htmlFor="csv-file" className="file-label">
        <div className="wrapper">
          <CSVReader
            cssClass="react-csv-input"
            label=""
            inputId="csv-file"
            onFileLoaded={handleFile}
            parserOptions={papaparseOptions}
          />
          <img src={fileIcon} alt="" />
          {fileName ? (
            <span className="filename">{fileName}</span>
          ) : (
            <span className="placeholder">{'Choose file'}</span>
          )}
        </div>
        <button type="button" className="btn btn-green">
          Browse
        </button>
      </label>

      <DateRangePicker />

      <Box sx={{ minWidth: 150 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Choose method</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={method}
            label="Choose method"
            onChange={handleChangeMethod}
          >
            <MenuItem value="OLS">OLS</MenuItem>
            <MenuItem value="TLS">TLS</MenuItem>
            <MenuItem value="min_half_life">min_half_life</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <button disabled={loading} type="submit" className="btn btn-yellow">
        Run
      </button>
      {report && (
        <CSVLink data={report.csv} className="btn btn-yellow btn-outline" filename="report.csv">
          Download report
        </CSVLink>
      )}
    </form>
  );
}

export default Filters;
