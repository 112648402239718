import store from '../../../../redux/store';
import { unixDate, valueOfDate } from '../../../../utils/dates';

const configurationData = {
  supports_marks: true,
  supports_search: true,
  supports_group_request: false,
  supports_timescale_marks: false,
  supported_resolutions: '1S',
};

const mapData = (data) => {
  let bars = data?.map((t) => {
    return {
      low: t.close,
      time: valueOfDate(t.time),
      high: t.close,
      open: t.close,
      close: t.close,
      // volume: t.volume,
    };
  });
  // console.log(bars);
  return bars || [];
};

const createSymbolInfo = (symbolName) => ({
  symbol: symbolName,
  full_name: symbolName,
  description: symbolName,
  exchange: symbolName,
  type: '',
  has_intraday: true,
  name: symbolName,
  // ticker: symbolName,
  // interval: configurationData.supported_resolutions[0],
  // session: '24x7',
  // has_seconds: true,
  // currency_code: 'USD',
  // format: 'volume',
});

const createMarkers = (o) => {
  let { uuid, ret } = o;
  const createMarker = (label, time, value) => {
    let color = label === 'SELL' ? 'red' : 'green';
    return {
      id: label + '-' + uuid,
      time: unixDate(time),
      color: { background: color, border: color },
      labelFontColor: 'white',
      label,
      minSize: 14,
      text: `<div>date: ${time}</div>
        <div>value: ${value.toFixed(3)}</div>
        <div>return: ${ret.toFixed(3)}</div>`,
    };
  };

  let m1, m2;
  if (o.side === -1) {
    m1 = createMarker('SELL', o.time_open, o.start_value);
    m2 = createMarker('BUY', o.time_close, o.end_value);
  } else {
    m1 = createMarker('BUY', o.time_open, o.start_value);
    m2 = createMarker('SELL', o.time_close, o.end_value);
  }
  return [m1, m2];
};

export const Datafeed = {
  onReady: (callback) => {
    setTimeout(() => {
      callback(configurationData);
    }, 0);
  },
  searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
    // console.log('[searchSymbols]: Method call');
    // console.log(userInput)
    let {
      tradingRules: { spread_symbols },
    } = store.getState();
    let newSymbols = spread_symbols.filter((symbol) => {
      const isFullSymbolContainsInput = symbol.toLowerCase().indexOf(userInput.toLowerCase()) !== -1;
      return isFullSymbolContainsInput;
    });

    newSymbols = newSymbols.map((s) => createSymbolInfo(s));
    onResultReadyCallback(newSymbols);
    // console.log(symbols, newSymbols)
  },

  resolveSymbol: (symbolName, onResolve, onError) => {
    let symbolInfo = createSymbolInfo(symbolName);

    new Promise((resolve) => {
      resolve(symbolInfo);
    })
      .then((resp) => onResolve(resp))
      .catch(onError);
  },

  getBars: (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
    let {
      tradingRules: { tv_spread },
    } = store.getState();
    // console.log(symbolInfo.name)
    let bars = mapData(tv_spread?.[symbolInfo.name]);
    // console.log(bars)
    const { from, to } = periodParams;
    // console.log(from, to)
    bars = bars.filter((bar) => bar.time >= from * 1000 && bar.time < to * 1000);
    // console.log(bars)
    onHistoryCallback(bars, { noData: false });
  },

  getMarks: (symbolInfo, startDate, endDate, onDataCallback) => {
    let {
      tradingRules: { trade_logs, selectedRowId },
    } = store.getState();
    const markers = trade_logs[selectedRowId].map((o) => createMarkers(o)).flat();
    onDataCallback(markers);
  },

  subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {},
  unsubscribeBars: (subscriberUID) => {},
};
