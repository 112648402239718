import axios from 'axios';
import { baseUrl } from '../config';
import { getConfig } from '../utils/axios';

export const getTableData = async (params) => {
  try {
    const res = await axios.post(baseUrl + '/pairs', params, getConfig());
    console.log(res.data);
    if (res.data.pairs.length === 0) alert("No data")
    return res.data
  } catch (err) {
    alert(err.message);
    return null
  }
}