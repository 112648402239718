import { baseUrl } from '../config';
import createAxiosRequest from '../utils/createAxiosRequest';

const request = createAxiosRequest({ baseURL: baseUrl });

export const login = (data) =>
  request({
    url: `/auth/user`,
    method: 'POST',
    data,
  });
