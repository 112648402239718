import { Routes, Route, Navigate } from 'react-router-dom';
import DataTable from './pages/DataTable/DataTable';
import Analysis from './pages/Analysis/Analysis';
import Login from './pages/Login';
import TradingRules from './pages/TradingRules/TradingRules';
import TradingList from './pages/TradingList';
import History from './pages/History';
import Header from './components/Header/Header';
import Dashboard from './components/Dashboard/Dashboard';
import { RequireAuth } from './components/routing/RequireAuth';

const App = () => (
  <div className="App">
    <Header />
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/registration" element={<p>Registration</p>} />
      <Route
        path="/"
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      >
        <Route exact path="/" element={<Navigate to="/table" />} />
        <Route path="/table" element={<DataTable />} />
        <Route path="/analysis" element={<Analysis />} />
        <Route path="/trading-rules" element={<TradingRules />} />
        <Route path="/trading-list" element={<TradingList />} />
        <Route path="/history" element={<History />} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </div>
);

export default App;
