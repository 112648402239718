import { useState } from 'react';
import styles from './notification.module.scss';
import { useSelector } from 'react-redux';
import { tableDataSelector } from '../../../redux/table';
import NotificationBox from '../../reusable/Notification/NotificationBox';

function Notification() {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (e, isExpanded) => {
    setExpanded((prev) => !prev);
  };

  const {
    report: { tickers },
  } = useSelector(tableDataSelector);
  let { all, filtered, missed, rejected } = tickers;
  // let { all: allDays, skipped } = days;

  return (
    <div className={styles.tableNotif}>
      <NotificationBox
        expanded={expanded}
        handleChange={handleChange}
        title="Results of the data upload and processing:"
      >
        <div className="success type">
          {filtered}/{all} - spreads are successfully created
        </div>
        <div className="rejected type">
          {rejected}/{all} - doesn't meet filters criteria
        </div>
        <div className="missed type">
          {missed}/{all} - price history wasn't received from data provider (please, check symbols names)
        </div>
        {/* <div className="missed type">
          {skipped}/{allDays} - observations from price history for each symbol were skipped and not used by library during the process of spreads' analysis
        </div> */}
      </NotificationBox>
    </div>
  );
}

export default Notification;
