import styles from './index.module.scss';

const mapButtonVariants = {
  red: styles.red,
  green: styles.green,
  whiteWithGreyBorder: styles.grayBorderVariant,
};

const Button = ({ children, extraClass = '', variant = 'whiteWithGreyBorder', ...props }) => (
  <button className={`${extraClass} ${mapButtonVariants[variant]} ${styles.wrapper}`} {...props}>
    {children}
  </button>
);

export default Button;
