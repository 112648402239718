import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './notification.module.scss'

function NotificationBox({children, title, expanded, handleChange}) {
  return (
    <div className={styles.notif}>
      <Accordion expanded={expanded} onChange={handleChange}
        sx={{backgroundColor: '#52956A', color: 'white'}}>
        <AccordionSummary
          sx={{height: '34px'}}
          expandIcon={<ExpandMoreIcon sx={{color: 'white'}}/>}
        >
          <p>{title}</p>
        </AccordionSummary>
        <AccordionDetails sx={{backgroundColor: 'white', color: 'black', py: 0}}>
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default NotificationBox