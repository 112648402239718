const globalTheme = {
  components: {
    MuiOutlinedInput: {
      // styleOverrides: {
      //   // Name of the slot
      //   root: {
      //     backgroundColor: 'white',
      //   },
      // },
      defaultProps: {
        // Name of the slot
        sx: {
          backgroundColor: 'white',
        },
        size: "small",
      },
    },
  },
}

export default globalTheme