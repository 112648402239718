import { useState } from "react";
import styles from './notification.module.scss'
import NotificationBox from "../../reusable/Notification/NotificationBox";
import { useSelector } from "react-redux";
import { tradingRulesSelector } from "../../../redux/tradingRules";
import { useUpdateEffect } from 'react-use';

function Notification() {
  const [expanded, setExpanded] = useState(false)
  const handleChange = (e, isExpanded) => {
    setExpanded(prev => !prev)
  }

  const { isTableActual } = useSelector(tradingRulesSelector)
  useUpdateEffect(() => {
    if (!isTableActual) setExpanded(true)
  }, [isTableActual])

  return (
    <div className={styles.trNotif}>
      <NotificationBox expanded={expanded} handleChange={handleChange}
        title='Notification alert'
      >
        <div className="notif-msg">
          New parameters will be applied to trading rules calculation only after optimization procedure is restarted. 
        </div>
      </NotificationBox>
    </div>
  )
}

export default Notification