import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import CollapseRow from './CollapseRow';

const TradingTable = ({ header, rows, nestedHeader, emptyListMessage }) => {
  return (
    <TableContainer component={Paper} sx={{ boxShadow: 'unset' }}>
      <Table aria-label="collapsible table">
        <TableHead
          sx={{ background: '#F5F8F9', height: '40px', th: { border: 'unset', padding: '0 8px', height: 40 } }}
        >
          <TableRow>
            {header.map((label, index) => (
              <TableCell key={label + index} sx={{ width: label ? '188px' : '40px', color: 'rgba(17, 17, 17, 0.48)' }}>
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {rows.length > 0 && (
          <TableBody sx={{ 'th, td': { border: 'unset', padding: '0 8px' } }}>
            {rows.map((row, i) => (
              <CollapseRow key={i} row={row} nestedHeader={nestedHeader} />
            ))}
          </TableBody>
        )}
      </Table>
      {rows.length === 0 && <p>{emptyListMessage}</p>}
    </TableContainer>
  );
};

export default TradingTable;
