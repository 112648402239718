import axios from 'axios';

const defaultRequestConfig = {
  onlyData: true,
};

const createAxiosRequest = ({ baseURL }) => {
  const requester = axios.create({ baseURL });

  const request = (config) => {
    const mergedConfig = { ...defaultRequestConfig, ...config };
    return requester(mergedConfig).then((res) => (mergedConfig.onlyData ? res.data : res));
  };

  return request;
};

export default createAxiosRequest;
