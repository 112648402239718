import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button';
import TradingTable from '../../components/TradingTable';
import Tabs from '../../components/Tabs';
import { generateSignals } from '../../api/trading-list';
import { getHistory } from '../../api/history';
import useFetchTradingList from '../../hooks/useFetchTradingList';
import { tradingListSelectors, tradingListActions } from '../../redux/modules/trading-list';

import styles from './index.module.scss';
import { createRowData, getTableHeaders } from './helper';
import { useEffect } from 'react';

const TradingList = () => {
  useFetchTradingList();
  const dispatch = useDispatch();

  const listData = useSelector(tradingListSelectors.selectTradingLists);
  const lastListCreateTime = useSelector(tradingListSelectors.selectLastListUpdatedTime);
  const lastRuleCreateTime = useSelector(tradingListSelectors.selectLastRuleUpdatedTime);
  const maxTime = Math.max(lastListCreateTime, lastRuleCreateTime);
  const lastCreateTime = new Date(maxTime).toLocaleString();

  const firstTabId = listData[0]?.id;
  const [activeListId, setActiveListId] = useState(firstTabId);

  useEffect(() => {
    if (!listData.some((l) => l.id !== activeListId)) {
      setActiveListId(firstTabId);
    }
  }, [listData.length]);

  const rules = useSelector((state) => tradingListSelectors.selectRulesByListId(state, activeListId || firstTabId));

  const rows = rules.map((r, i) => createRowData(r));
  const [header, nestedHeader] = getTableHeaders();

  const handelDeleteList = (id) => {
    const deleteIndex = listData.findIndex((l) => l.id === id);
    const newIndex = deleteIndex === 0 ? 1 : 0;

    listData[newIndex] && setActiveListId(listData[newIndex].id);
    dispatch(tradingListActions.deleteTradingList(id));
  };

  const downloadHistory = useCallback(async () => {
    const history = await getHistory();

    const blob = new Blob([history.history_csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.setAttribute('href', url);
    a.setAttribute('history', 'download.csv');
    a.click();
  }, []);

  return (
    <>
      <div className={styles.topContentWrapper}>
        <div className={styles.topContentInner}>
          <div className={styles.info}>
            <p>Trading list</p>
            <span>Last update {lastCreateTime}</span>
          </div>
          <div className={styles.exportContainer}>
            <Button variant="green" onClick={generateSignals}>
              Generate signals
            </Button>
            {/* <DateRangePicker /> */}
            <Button onClick={downloadHistory}>Export history .csv</Button>
          </div>
        </div>
      </div>
      <div className={styles.tableWrapper}>
        <Tabs
          emptyText="Please add list"
          tabsList={listData}
          initTab={activeListId}
          onDelete={handelDeleteList}
          onTabChange={(data) => setActiveListId(data.id)}
        >
          {listData.map((_, i) => (
            <TradingTable key={i} {...{ header, rows, nestedHeader }} emptyListMessage="Rules list is empty" />
          ))}
        </Tabs>
      </div>
    </>
  );
};

export default TradingList;
