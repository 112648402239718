import Modal from '../Modal';
import Button from '../Button';

import styles from './index.module.scss';

const ConfirmDelete = ({ onDelete, title = 'Are you sure?', description, ...props }) => (
  <Modal {...props}>
    <div>
      <h3 className={styles.title}>{title}</h3>
      <span className={styles.subtitle} dangerouslySetInnerHTML={{ __html: description }} />
      <div className={styles.buttons}>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button variant="red" onClick={onDelete}>
          Delete
        </Button>
      </div>
    </div>
  </Modal>
);

export default ConfirmDelete;
