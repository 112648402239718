import { call, fork, take, select, put } from 'typed-redux-saga';

import {
  createOrUpdateList,
  getListAndData,
  deleteList,
  addRuleToList,
  deleteRuleFromList,
} from '../../../api/trading-list';
import asyncEntity from '../../../utils/redux/asyncEntity';
import { selectTradingRuleByIndex } from '../../tradingRules';
import { selectAnalysisSymbols, selectAnalysisStatisticsHedgeRatio } from '../../analysis';

import { tradingListActions, tradingListSelectors } from '.';

const createListAsyncRequest = asyncEntity(tradingListActions.createOrUpdateListAsync, (data) =>
  createOrUpdateList(data)
);

function* watchCreateOrUpdateList() {
  while (true) {
    const { payload } = yield take(tradingListActions.createOrUpdateList);

    yield fork(createListAsyncRequest, { name: payload.name }, payload);
  }
}

function* watchCreateOrUpdateListSuccess() {
  while (true) {
    const { payload } = yield take(tradingListActions.createOrUpdateListAsync.success);

    const { ruleIndex } = yield select(tradingListSelectors.selectCreateListParams);

    if (ruleIndex) {
      yield put(tradingListActions.saveRule({ ruleIndex, listId: payload.id }));
    }
  }
}

const getListRequest = asyncEntity(tradingListActions.fetchTradingListAsync, () => getListAndData());

function* watchFetchTradingList() {
  while (true) {
    const { payload } = yield take(tradingListActions.fetchTradingList);

    yield fork(getListRequest, payload);
  }
}

const deleteListRequest = asyncEntity(tradingListActions.deleteTradingListAsync, (params) => deleteList(params));

function* watchDeleteTradingList() {
  while (true) {
    const { payload } = yield take(tradingListActions.deleteTradingList);

    yield fork(deleteListRequest, payload);
  }
}

function* createRuleData({ ruleIndex, listId }) {
  const [, leg2, leg1] = yield select(selectAnalysisSymbols);
  const hedgeRatio = yield select(selectAnalysisStatisticsHedgeRatio);

  const rule = yield select((state) => selectTradingRuleByIndex(state, ruleIndex));

  const data = {
    id: rule.ID,
    leg_1: leg1,
    leg_2: leg2,
    list_id: listId,
    hedge_ratio: hedgeRatio,
    sharpe: rule['Sharpe Ratio'],
    sl_sigma: rule['SL sigma'],
    total_return: rule['Return'],
    max_drawdown: rule['Max drawdown'],
    z_score_entry: rule['Z-score entry'],
    z_score_exit_delta: rule['Z-score exit'],
    estimation_window: rule['Estimation Window'],
    num_days_in_position: rule['Days in position'],
  };

  return data;
}

const saveRuleRequest = asyncEntity(tradingListActions.saveRuleAsync, (params) => addRuleToList(params));

function* watchSaveRuleList() {
  while (true) {
    const { payload } = yield take(tradingListActions.saveRule);

    const data = yield createRuleData(payload);

    yield fork(saveRuleRequest, data);
  }
}

const deleteRuleRequest = asyncEntity(tradingListActions.deleteRuleAsync, (params) => deleteRuleFromList(params));

function* watchDeleteRuleList() {
  while (true) {
    const { payload } = yield take(tradingListActions.deleteRule);

    yield fork(deleteRuleRequest, payload);
  }
}

const getSagas = () => [
  call(watchSaveRuleList),
  call(watchDeleteRuleList),
  call(watchFetchTradingList),
  call(watchDeleteTradingList),
  call(watchCreateOrUpdateList),
  call(watchCreateOrUpdateListSuccess),
];

export default getSagas;
